import React, { useState, useEffect } from 'react';
import { Button, Segment, Progress, Icon, Grid } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

// TODO: this chunking should move to the backend, and may be even chunk it up at the AI generation level
function breakTextIntoChunks(text) {
    // Split the text into paragraphs
    const paragraphs = text.split(/\n\s*\n/);
    
    // Function to split paragraph into sentences
    const splitIntoSentences = (paragraph) => {
        return paragraph.match(/[^.!?]+[.!?]+/g) || []; // Match sentences ending with ., !, or ?
    };

    const chunks = [];

    paragraphs.forEach(paragraph => {
        const sentences = splitIntoSentences(paragraph);
        let chunk = '';

        sentences.forEach((sentence, index) => {
            // Add the sentence to the current chunk
            chunk += sentence.trim() + ' ';
            
            // Check if we have reached 1-3 sentences
            if ((index + 1) % 2 === 0 || index === sentences.length - 1) {
                chunks.push(chunk.trim());
                chunk = ''; // Reset the chunk
            }
        });
    });

    return chunks;
}

const LearningComponent = ({ currentTask }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    
    const topicsMaterial = breakTextIntoChunks(currentTask.courseTask?.topics_material) || [];

    // Reset the current index when the currentTask changes
    useEffect(() => {
        setCurrentIndex(0);
    }, [currentTask]);

    const nextCard = () => {
        if (currentIndex < topicsMaterial.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prevCard = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const progress = Math.round(((currentIndex + 1) / topicsMaterial.length) * 100);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p style={{ margin: 0 }}><strong>{currentTask.content}</strong></p>
                </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                <p>{currentTask.description}</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid.Column>
                {currentTask.courseTask && (
                        <Segment>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Button 
                                    icon 
                                    basic
                                    onClick={prevCard} 
                                    disabled={currentIndex === 0} 
                                    style={{ marginRight: '10px', boxShadow: 'none' }} 
                                >
                                    <Icon name='chevron left' />
                                </Button>
                                <div style={{ flex: 1, textAlign: 'center' }}>
                                    {topicsMaterial.length > 0 && (
                                        <div>
                                            <ReactMarkdown>{topicsMaterial[currentIndex]}</ReactMarkdown>
                                            <Progress percent={progress} progress indicating />
                                        </div>
                                    )}
                                </div>
                                <Button 
                                    icon 
                                    basic
                                    onClick={nextCard} 
                                    disabled={currentIndex === topicsMaterial.length - 1} 
                                    style={{ marginRight: '10px', boxShadow: 'none' }} 
                                >
                                    <Icon name='chevron right' />
                                </Button>
                            </div>
                        </Segment>
                )}
            </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default LearningComponent;
