import React, { useState, useEffect } from 'react';
import { Button, Icon, Segment, Message } from 'semantic-ui-react';
import { useParams, useNavigate } from 'react-router-dom';
import ResumeForReview from './ResumeForReview'; // Adjust the import based on your folder structure

const ResumeForReviewPage = () => {
    const { resume_id } = useParams(); // Get the resume_id from the URL
    const [resume, setResume] = useState(null); // Set initial state to `null`
    const [isPrivate, setIsPrivate] = useState(false); // Track if the resume is private
    const [isLoading, setIsLoading] = useState(true); // Track loading state

    const navigate = useNavigate();

    // Fetch resume details from the backend API
    const fetchResume = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND}/api/resume/get_one_by_resume_id/${resume_id}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                }
            );

            // Check if the resume is private (403 status)
            if (response.status === 403) {
                setIsPrivate(true);
                setIsLoading(false); // Stop loading
                return;
            }

            if (!response.ok) throw new Error('Failed to fetch resume');
            
            const data = await response.json();
            setResume(data);
            setIsLoading(false); // Stop loading
        } catch (error) {
            console.error('Error fetching resume:', error);
            setIsLoading(false); // Stop loading in case of error
        }
    };

    useEffect(() => {
        if (resume_id) {
            fetchResume();
        }
    }, [resume_id]);

    // Loading state
    if (isLoading) {
        return <Message info>Loading resume details...</Message>;
    }

    // Resume is private
    if (isPrivate) {
        return (
            <Segment>
                <Message warning>This resume is private and cannot be viewed.</Message>
                <Button size="small" icon labelPosition='left' onClick={() => navigate(-1)} style={{ margin: '5px' }}>
                    <Icon name='left arrow' />
                    Back
                </Button>
            </Segment>
        );
    }

    return (
        resume ? (
            <Segment>
                {/* Back Button */}
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
                    <Button size="small" icon labelPosition='left' onClick={() => navigate(-1)} style={{ margin: '5px' }}>
                        <Icon name='left arrow' />
                        Back
                    </Button>
                </div>

                {/* Resume Section */}
                <ResumeForReview resume={resume} />
            </Segment>
        ) : (
            <Message error>Failed to load resume.</Message>
        )
    );
};

export default ResumeForReviewPage;
