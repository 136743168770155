import React, { useState, useEffect } from 'react';
import { Table, Pagination, Checkbox, Input } from 'semantic-ui-react';

const CompanyTable = () => {
  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [filterByWorkLifeBalance, setFilterByWorkLifeBalance] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams({
        page: currentPage,
        limit: entriesPerPage,
        sort: column || 'created_at',
        order: direction === 'ascending' ? 'asc' : 'desc',
        work_life_balance: filterByWorkLifeBalance ? 'true' : 'false',
        name: searchQuery // Add search query parameter
      });

      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/companies?${queryParams.toString()}`);
      const { companies, total, pages } = await response.json();

      setCompanies(companies);
      setTotalPages(pages);
    };

    fetchData();
  }, [currentPage, entriesPerPage, column, direction, filterByWorkLifeBalance, searchQuery]);  // Include all effect dependencies

  const handleSort = (clickedColumn) => {
    const newDirection = column === clickedColumn && direction === 'ascending' ? 'descending' : 'ascending';
    setColumn(clickedColumn);
    setDirection(newDirection);
    // Force a refetch when sorting changes
    setCurrentPage(1); // Optionally reset to the first page
  };

  const handlePaginationChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div>
      <Input
      style={{marginRight: '5px'}}
        icon="search"
        placeholder="Search by company name..."
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <Checkbox
        label="Show curated companies only"
        checked={filterByWorkLifeBalance}
        onChange={(e, { checked }) => setFilterByWorkLifeBalance(checked)}
      />
      <Table sortable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === 'name' ? direction : null}
              onClick={() => handleSort('name')}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'blind_url' ? direction : null}
              onClick={() => handleSort('blind_url')}
            >
              Blind URL
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'createdAt' ? direction : null}
              onClick={() => handleSort('createdAt')}
            >
              Created At
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'career_growth' ? direction : null}
              onClick={() => handleSort('career_growth')}
            >
              Career Growth
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'career_growth_categorization' ? direction : null}
              onClick={() => handleSort('career_growth_categorization')}
            >
              Career Growth Category
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'work_life_balance' ? direction : null}
              onClick={() => handleSort('work_life_balance')}
            >
              Work Life Balance
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'work_life_balance_categorization' ? direction : null}
              onClick={() => handleSort('work_life_balance_categorization')}
            >
              Work Life Balance Category
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'compensation_and_benefits' ? direction : null}
              onClick={() => handleSort('compensation_and_benefits')}
            >
              Compensation & Benefits
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'compensation_and_benefits_categorization' ? direction : null}
              onClick={() => handleSort('compensation_and_benefits_categorization')}
            >
              Compensation & Benefits Category
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'company_culture' ? direction : null}
              onClick={() => handleSort('company_culture')}
            >
              Company Culture
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'company_culture_categorization' ? direction : null}
              onClick={() => handleSort('company_culture_categorization')}
            >
              Company Culture Category
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'management' ? direction : null}
              onClick={() => handleSort('management')}
            >
              Management
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'management_categorization' ? direction : null}
              onClick={() => handleSort('management_categorization')}
            >
              Management Category
            </Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Image URL</Table.HeaderCell>
            <Table.HeaderCell>Website</Table.HeaderCell>
            <Table.HeaderCell>Industry</Table.HeaderCell>
            <Table.HeaderCell>Location</Table.HeaderCell>
            <Table.HeaderCell>Founded</Table.HeaderCell>
            <Table.HeaderCell>Size</Table.HeaderCell>
            <Table.HeaderCell>Normalized Size</Table.HeaderCell>
            <Table.HeaderCell>Size Category</Table.HeaderCell>
            <Table.HeaderCell>Salary</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {companies.map((company, index) => (
            <Table.Row key={index}>
              <Table.Cell>{company.name}</Table.Cell>
              <Table.Cell>{company.blind_url}</Table.Cell>
              <Table.Cell>{company.createdAt.split("T")[0]}</Table.Cell>
              <Table.Cell>{company.career_growth}</Table.Cell>
              <Table.Cell>{company.career_growth_categorization}</Table.Cell>
              <Table.Cell>{company.work_life_balance}</Table.Cell>
              <Table.Cell>{company.work_life_balance_categorization}</Table.Cell>
              <Table.Cell>{company.compensation_and_benefits}</Table.Cell>
              <Table.Cell>{company.compensation_and_benefits_categorization}</Table.Cell>
              <Table.Cell>{company.company_culture}</Table.Cell>
              <Table.Cell>{company.company_culture_categorization}</Table.Cell>
              <Table.Cell>{company.management}</Table.Cell>
              <Table.Cell>{company.management_categorization}</Table.Cell>
              <Table.Cell>{company.description}</Table.Cell>
              <Table.Cell>{company.image_url}</Table.Cell>
              <Table.Cell>{company.website}</Table.Cell>
              <Table.Cell>{company.industry}</Table.Cell>
              <Table.Cell>{company.location}</Table.Cell>
              <Table.Cell>{company.founded}</Table.Cell>
              <Table.Cell>{company.size}</Table.Cell>
              <Table.Cell>{company.normalized_size}</Table.Cell>
              <Table.Cell>{company.size_category}</Table.Cell>
              <Table.Cell>{company.salary}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='22'>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePaginationChange}
                fluid
                style={{justifyContent: 'center'}}
                secondary
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};

export default CompanyTable;
