import React, { useState, useEffect } from 'react';
import { Container, Header, List, Segment, Card, Label, Input, Image, CardHeader, Button, Icon, CardMeta, CardContent, Dropdown, Checkbox, Modal } from 'semantic-ui-react';
import ResumeForm from './ResumeForm';
import Resume from './Resume';
import { allowFeature } from '../feature_flags_service';
import { toast } from 'react-toastify';
import { addOption, getOptions } from '../optionsHelpers';
import { referralOptions } from '../displayHelpers';
import CertificateList from './CertificatesList';

const isOwner = (ownerId) => {
  return localStorage.getItem('userId') === ownerId;
};

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
};

const Profile = ({flags}) => {
  const [user, setUser] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [resume, setResume] = useState(null);
  const [editMode, setEditMode] = useState(false)
  const [file, setFile] = useState(null);
  const [isResumePublic, setIsResumePublic] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Capture the file from input
  };

  const handleCheckboxChange = async () => {
    // Toggle the local UI state
    const newStatus = !isResumePublic;
    setIsResumePublic(newStatus);

    if (newStatus) {
      // If enabling, show the disclaimer modal
      setModalOpen(true);
    } else {
      // Directly update if disabling
      updateRevealResumeStatus(newStatus);
    }
  };

  const handleModalClose = () => {
    // If they close the modal without confirming, revert the checkbox
    setIsResumePublic(false);
    setModalOpen(false);
  };

  const handleConfirm = () => {
    // User confirmed, update the status and close the modal
    setIsResumePublic(true);
    updateRevealResumeStatus(true);
    setModalOpen(false);
  };

  const updateRevealResumeStatus = async (reveal) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/users`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ reveal_resume: reveal })
      });

      if (!response.ok) throw new Error('Failed to update resume reveal status');
      toast.success("Resume reveal status updated successfully")
    } catch (error) {
      toast.success("Error updating resume reveal status")
      console.error('Error updating resume reveal status:', error);
    }
  };

  const fetchCompanyOptions = async () => {
    const companyOptions = await getOptions('company')
    setCompanyOptions(companyOptions)
  }
  
  const handleCompanyAddition = async (e, { value }) => {
    await addOption('company', value)
    await fetchCompanyOptions()
  };

  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  const updateCurrentCompany = async (company) => {
    const selectedOption = companyOptions.find(option => option.value === company);
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/users/`,
          {
              method: 'PATCH',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              },
              body: JSON.stringify({ 
                current_company: company,
                current_company_id: selectedOption.key
               }),
          }
          );
      if (!response.ok) throw new Error('Failed to fetch resume');
      const data = await response.json();
      toast.success("Updated current company")
      fetchUserProfile()
    } catch (error) {
      toast.error("Failed to update current company")
      console.error('Failed to update current company:', error);
    }
  }

  const updateReferralStance = async (e, {name, value}) => {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/users/`,
          {
              method: 'PATCH',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              },
              body: JSON.stringify({ 
                referral_stance: value
               }),
          }
          );
      if (!response.ok) throw new Error('Failed to fetch resume');
      const data = await response.json();
      fetchUserProfile()
      toast.success("Updated referral stance")
    } catch (error) {
      toast.error("Failed to update referral stance")
      console.error('Failed to update referral stance:', error);
    }
  }

  const handleUpload = async () => {
    if (!file) {
      toast.error('Please select a file to upload');
      return;
    }
    const formData = new FormData();
    formData.append('profilePhoto', file);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/users/upload-profile-photo`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: formData
      });

      if (!response.ok) throw new Error('Failed to upload photo');

      toast.success('Photo uploaded successfully!');
      fetchUserProfile()
    } catch (error) {
      console.error('Error uploading photo:', error);
      toast.error('Error uploading photo');
    }
  };

  const fetchResume = async () => {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/resume/`,
          {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              }
          }
          ); // Adjust endpoint as necessary
      if (!response.ok) throw new Error('Failed to fetch resume');
      const data = await response.json();
      setResume(data);
    } catch (error) {
      console.error('Error fetching resume:', error);
    }
  };
  const fetchUserProfile = async () => {
    try {
      const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/profile`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
              }
            }
          );
      const data = await response.json();
      setUser(data);
      setIsResumePublic(data.reveal_resume);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {

    fetchUserProfile();
    
    if (allowFeature(flags, 'resumes')) {
      fetchResume();
    }
    
    fetchCompanyOptions()
  }, []);

  if (isLoading) {
    return <Segment loading>Loading...</Segment>;
  }

  if (!user) {
    return <Segment>No user profile data found.</Segment>;
  }

  const isEmptyCollection = (collection) => !collection || collection.length === 0;

  return (
    <Container>
      <Card>
      {
      user.profileImage ?
      <>
        <Image src={user.profileImage} wrapped size="medium" ui={false} />
        <Input type="file" onChange={handleFileChange} />
        <Button onClick={handleUpload} primary icon>
          <Icon name='upload' /> Change Photo
        </Button>
      </> 
      :
      <>
        <Input type="file" onChange={handleFileChange} />
        <Button onClick={handleUpload} primary icon>
          <Icon name='upload' /> Upload Photo
        </Button>
      </>
      }
      <CardContent>
        <CardHeader>{user.fullname}</CardHeader>
        <CardMeta>
          {user.createdAt && (
            <Label color="teal" size="large">
              Member Since: {formatDate(user.createdAt)}
            </Label>
          )}
        </CardMeta>
      </CardContent>
    </Card>

    {
      user.current_company ?
      <Label style={{margin: '5px 0'}} color='teal'> <Icon name='building'/> {user.current_company} </Label> : null
    }

    {
      isOwner(user._id) ?
      <div style={{ margin: '5px 0' }}>
        <Header as="h5" sub>Would you refer someone?</Header>
        <Dropdown
          placeholder="Select your referral stance"
          selection
          search
          options={referralOptions}
          value={user.referral_stance}
          onChange={updateReferralStance}
        />
      </div>
       :
    user.referral_stance ?
      <Label> {user.referral_stance} </Label>
      : null
  }

  {
        isOwner(user._id) && !!resume ?
        <div style={{margin: 5}}>
            {
              allowFeature(flags, 'resumes') ? <Checkbox
                label="Make my resume available for review"
                checked={isResumePublic}
                onChange={handleCheckboxChange}
              /> : null
            }

            <Modal
              open={modalOpen}
              onClose={handleModalClose}
              size='small'
              dimmer='blurring'
            >
              <Modal.Header>Public Resume Disclaimer</Modal.Header>
              <Modal.Content>
                <p>By opting to make your resume public on this platform, you agree to anonymously share your employment history with other members of the platform. Your name and other personal details will be hidden. You can make your resume private at any time.</p>
              </Modal.Content>
              <Modal.Actions>
                <Button negative onClick={handleModalClose}>
                  Cancel
                </Button>
                <Button positive onClick={handleConfirm}>
                  I Agree
                </Button>
              </Modal.Actions>
            </Modal>
          </div>
        :
      null
    }
      
      {!isEmptyCollection(user.roles) && (
        <List>
          <List.Item>
            <strong>Roles:</strong>
            {user.roles.map((role, index) => (
              <Label key={index}>{role}</Label>
            ))}
          </List.Item>
        </List>
      )}

      {!isEmptyCollection(user.project_involvements) && (
        <Card.Group>
          <Header as='h3'>Project Involvements</Header>
          {user.project_involvements.map((project, index) => (
            <Card key={index}>
              <Card.Content>
                <Card.Header>{project.name}</Card.Header>
                <Card.Meta>{project.status}</Card.Meta>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      )}

      {
        user.crash_course_certs?.length > 0 ? <Segment>
          <CertificateList certificateIds={user.crash_course_certs} />
        </Segment> : null
      }
      {allowFeature(flags, 'resumes') && (!resume || editMode) && <ResumeForm editMode={editMode} resumeData={resume} toggleEditMode={toggleEditMode} fetchResume={fetchResume}/>}
      {allowFeature(flags, 'resumes') && (resume && !editMode) && <Resume resume={resume} editPdf={toggleEditMode}/>}
    </Container>
  );
};

export default Profile;
