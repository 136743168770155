import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useNavigate, Link } from 'react-router-dom';

const ProjectApplicationButton = ({ project, toggleApplicationModal }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isPaidMember = localStorage.getItem('isPro') === 'true';
  const navigate = useNavigate();

  const handleSubscribe = () => {
    setIsModalOpen(false);
    navigate('/account');
  };

  const ButtonBody = ({project}) => {
    if (project.involved_project) {
      return <>
          <Button primary as={Link} to={`/projects/${project.involved_project}`}>
            Go to {project.projectType === 'teachable_course' ? 'Course' : 'Project'}
          </Button>
      </>
    }
    if (project.status === 'recruiting') {
      if (!project.pro || (project.pro && isPaidMember)) {
        return <Button primary onClick={() => toggleApplicationModal(project._id)}>
            Apply to Project
          </Button>
      } else {
        return <Button primary onClick={() => setIsModalOpen(true)}>
          Apply to Project
        </Button>
      }
    }
    return null
  }

  return (
    <>
      <ButtonBody project={project}/>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Header>Subscription Required</Modal.Header>
        <Modal.Content>
          <p>This project requires a pro membership. Would you like to subscribe?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setIsModalOpen(false)}>No</Button>
          <Button primary onClick={handleSubscribe}>Go to Accounts Page to Subscribe</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ProjectApplicationButton;
