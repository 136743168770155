import React, { useEffect, useState } from 'react';
import { DataSet } from 'vis-timeline/standalone/umd/vis-timeline-graph2d.min.js';
import { Form, Button, Checkbox, Segment, Divider, Container, Label, List, Icon, Header, TextArea, Dropdown, ButtonGroup, Modal, Popup, Grid } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { formatParticipants, projectTimelineOptions, generateSkillOptions } from '../displayHelpers';
import AddNewTaskForExistingTimelineForm from './AddNewTaskForExistingTimelineForm';
import TaskItem from './TaskItem';
import ProjectApplicationsForProject from './ProjectApplicationsForProject';

const teamMemberOptions = (participants) => {
    return participants.map(p => (
        {
            key: p.user._id,
            value: p.user._id,
            text: p.user.fullname,
        }
    ))
}

function addDaysToDate(date, daysToAdd) {
    let result = new Date(date);  // Create a new Date object to avoid mutating the original date
    result.setDate(result.getDate() + daysToAdd);  // Add the days
    return result;
}

const mapEndDateToDays = (timeline) => {
    const timelineToDays = {
        '1w': 7,
        '2w': 7 * 2,
        '3w': 7 * 3,
        '4w': 7 * 4,
        '6w': 7 * 6,
        '6w+': 7 * 52,
    }
    return timelineToDays[timeline] || 7 * 4; // default to 4 week window if timeline not given
}

const isCreator = (creatorId) => {
    return localStorage.getItem('userId') === creatorId;
};

const TaskTimeline = ({projectId, toggleModal, fetchProjects}) => {
    const [startDate, setStartDate] = useState(new Date());
    const [startDateModalOpen, setStartDateModalOpen] = useState(false);
    const [tasks, setTasks] = useState(new DataSet([]));
    const [project, setProject] = useState({
        background: '',
        features: [],
        technologies: [],
        timeline: ''
    });
    const [loading, setLoading] = useState(false)
    const [taskForm, setTaskForm] = useState({ content: '', description: '', duration: '', dependencies: [], startDate: new Date().toISOString().split('T')[0] });

    const [projectApplicationsModalOpen, setProjectApplicationsModalOpen] = useState(false);

    const handleProjectApplicationsModalToggle = () => {setProjectApplicationsModalOpen(!projectApplicationsModalOpen)};

    const fetchProject = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/get_one/${projectId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            if (response.ok) {
                const responseData = await response.json();
                setProject(responseData);
                setLoading(false)
            } else {
                setLoading(false)
                console.error('Failed to fetch project');
            }
        } catch (error) {
            setLoading(false)
            console.error('Error fetching project:', error);
        }
    };

    useEffect(() => {
        fetchProject()
    }, [])

    const fetchProjectDetails = async () => {
        if (!project.project_timeline) {
            setTaskForm(prev => ({ ...prev, startDate: new Date().toISOString().split('T')[0] }));
            setTasks(new DataSet([]));
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/project_timeline/${project.project_timeline}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
            });
            if (!response.ok) throw new Error('Failed to fetch project details');
            
            const project_timeline = await response.json();
            setTaskForm(prev => ({ ...prev, startDate: project_timeline.startDate.split('T')[0] }));

            setTasks(new DataSet(project_timeline.tasks.map(task => ({
                ...task,
                id: task._id,
                start: new Date(task.start),
                end: new Date(task.end),
                assignees: task.assignees.map(assignee => assignee._id),
                taskStatus: task.taskStatus,
                group: 1
            }))));

            setStartDate(new Date(project_timeline.startDate))
        } catch (error) {
            console.error('Error fetching project details:', error);
        }
    };
    
    useEffect(() => {
        // This is solely for fetching project details
        fetchProjectDetails();
    }, [project.project_timeline, project._id]);  // Dependencies related only to fetching data

    const handleInputChange = (e, { name, value }) => {
        if (name === 'features') {
            // Assuming features can be added as an array
            setProject(prev => ({ ...prev, features: [...prev.features, value] }));
        } else {
            setProject(prev => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/update/${projectId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify(project)
            });
            if (response.ok) {
                setLoading(false)
                toast.success('Project updated successfully!');
            } else {
                throw new Error('Failed to update project');
            }
        } catch (error) {
            setLoading(false)
            console.error('Error updating project:', error);
            toast.error('Failed to update project');
        }
    };

    const updateProjectStatus = async (newStatus) => {
        setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/updateStatus/${projectId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify({status: newStatus})
            });
            if (response.ok) {
                setLoading(false)
                toast.success('Project status updated successfully!');
            } else {
                throw new Error('Failed to update project status');
            }
        } catch (error) {
            setLoading(false)
            console.error('Error updating project status:', error);
            toast.error('Failed to update project status');
        }
    };
    
    const handleAddTask = () => {
        if (!taskForm.content || !taskForm.duration) return;

        // Compute the earliest start date based on dependencies
        const maxEndDate = taskForm.dependencies.reduce((acc, curr) => {
            const dependentTask = tasks.get(curr);
            return new Date(dependentTask.end) > new Date(acc) ? dependentTask.end : acc;
        }, taskForm.startDate);

        const startDate = new Date(maxEndDate);
        startDate.setSeconds(startDate.getSeconds() + 1); // Add a second to start the task
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + parseInt(taskForm.duration));
        const newTask = {
            id: tasks.length + 1,
            content: taskForm.content,
            description: taskForm.description,
            start: startDate.toISOString().split('T')[0],
            end: endDate.toISOString().split('T')[0],
            group: 1
        };

        tasks.add(newTask);
        setTaskForm(prev => ({ ...prev, content: '', description: '', duration: '', dependencies: [] }));
    };

    const handleChange = (e, { name, value, checked }) => {
        if (name === 'dependencies') {
            setTaskForm(prev => ({
                ...prev,
                dependencies: checked
                    ? [...prev.dependencies, parseInt(value)]
                    : prev.dependencies.filter(dep => dep !== parseInt(value))
            }));
        } else {
            setTaskForm(prev => ({ ...prev, [name]: value }));
        }
    };

    const createAiTimeline = async () => {   
        setLoading(true)
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/createAiTimeline/${projectId}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              },
            });
        
            if (!response.ok) {
                setLoading(false)
                const result = await response.json()
                toast.error(result.message)
                return
            };
        
            const result = await response.json();
            toast.success("Created AI project timeline successfully!")
            fetchProject()
            setLoading(false)
          } catch (error) {
            setLoading(false)
            toast.error("Failed to create AI project timeline")
            console.error('Error AI creating project:', error);
          }
    }

    const deleteTimeline = async() => {
        setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/project_timeline/${project.project_timeline}`, {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              },
            });
        
            if (!response.ok) {
                setLoading(false)
                toast.error("Failed to failed project timeline")
                return
            };
            setLoading(false)
            fetchProject()
            toast.success("Deleted project timeline successfully!")
          } catch (error) {
            setLoading(false)
            toast.error("Failed to delete project timeline")
            console.error('Error deleting project:', error);
          }
    }

    const cloneProject = async() => {
        setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/clone/${project._id}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              },
            });
        
            if (!response.ok) {
                setLoading(false)
                toast.error("Failed to clone project")
                return
            };
        
            const result = await response.json();
            toast.success("Cloned project successfully!")
            setLoading(false)
            fetchProjects()
            toggleModal(project._id) // to allow the Timeline to be refresh on reopen
          } catch (error) {
            setLoading(false)
            toast.error("Failed to clone project")
            console.error('Error cloning project:', error);
          }
        
    }

    const cloneProjectWithApplications = async() => {
        setLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/cloneWithApplications/${project._id}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              },
            });
        
            if (!response.ok) {
                setLoading(false)
                toast.error("Failed to clone project")
                return
            };
        
            const result = await response.json();
            toast.success("Cloned project successfully!")
            setLoading(false)
            fetchProjects()
            toggleModal(project._id) // to allow the Timeline to be refresh on reopen
          } catch (error) {
            setLoading(false)
            toast.error("Failed to clone project")
            console.error('Error cloning project:', error);
          }
        
    }

    const submitTimeline = async() => {
        setLoading(true)
        const projectDetails = {
            startDate: taskForm.startDate,
            endDate: project.timeline ? addDaysToDate(taskForm.startDate, mapEndDateToDays(project.timeline)) : null,
            tasks: tasks.get().map(task => ({
              content: task.content,
              description: task.description,
              start: task.start,
              end: task.end,
              dependencies: task.dependencies
            }))
          };
        
          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/project/${project._id}/timeline`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              },
              body: JSON.stringify(projectDetails)
            });
        
            if (!response.ok) {
                setLoading(false)
                toast.error("Failed to create project timeline")
                return
            };
        
            const result = await response.json();
            toast.success("Created project timeline successfully!")
            await fetchProjectDetails()
            setLoading(false)
            fetchProjects()
            toggleModal(project._id) // to allow the Timeline to be refresh on reopen
          } catch (error) {
            setLoading(false)
            toast.error("Failed to create project timeline")
            console.error('Error creating project:', error);
          }
        
    };

    const handleStartDateModalToggle = () => {
        setStartDateModalOpen(!startDateModalOpen);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleStartDateUpdateSubmit = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/updateStartDate/${projectId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({ startDate: startDate.toISOString() })
            });

            if (!response.ok) throw new Error('Failed to update start date');
            toast.success('Start date updated successfully');
            handleStartDateModalToggle();
            fetchProjectDetails()
        } catch (error) {
            console.error('Error updating start date:', error);
            toast.error('Error updating start date');
        }
    };

    const isCreatorOrParticipant = () => {
        return (project.project_creator === localStorage.getItem('userId')) ||
        (project.participants.some(p => p.user._id === localStorage.getItem('userId')));
    };

    const updateTaskAssignees = async (taskId, assignees) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/${taskId}/assignees`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify({ assignees })
            });
            if (response.ok) {
                toast.success('Assignees updated successfully!');
            } else {
                throw new Error('Failed to update assignees');
            }
        } catch (error) {
            console.error('Error updating assignees:', error);
            toast.error('Failed to update assignees');
        }
    };

    // Add handler for assignee changes
    const handleAssigneeChange = (value, selectedTaskId) => {
        setTasks(prevTasks => {
            const updatedTasks = prevTasks.map(task => {
                if (task._id === selectedTaskId) { // Ensure you have a way to identify the selected task
                    return {...task, assignees: value};
                }
                return task;
            });
            return new DataSet(updatedTasks);
        });
        updateTaskAssignees(selectedTaskId, value)
    };

    const handleTaskStatusChange = (value, selectedTaskId) => {
        setTasks(prevTasks => {
            const updatedTasks = prevTasks.map(task => {
                if (task._id === selectedTaskId) { // Ensure you have a way to identify the selected task
                    return {...task, taskStatus: value};
                }
                return task;
            });
            return new DataSet(updatedTasks);
        });
        updateTaskDone(selectedTaskId, value)
    };

    const updateTaskDone = async (taskId, newTaskStatus) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/${taskId}/taskStatus`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Assuming you use bearer token for auth
                },
                body: JSON.stringify({ taskStatus: newTaskStatus, projectId: projectId })
            });
    
            if (response.ok) {
                const updatedTask = await response.json();
                toast.success('Task status updated successfully!');
            } else {
                throw new Error('Failed to update task status');
            }
        } catch (error) {
            console.error('Error updating task status:', error);
            toast.error('Error updating task status');
        }
    };

    return (
        <Segment.Group>
            <Segment loading={loading}>
                {['study_group'].includes(project.projectType) ? 
                    <Label size='large'>
                    <Icon name='linkify' />
                    <a href={project.course_link} target='_blank' rel='noopener noreferrer'>{project.course_title}</a>
                </Label> : null    
                }
                <Form>
                    <Form.Field>
                        <label>Title</label>
                        <Form.Input
                            type="text"
                            name="projectTitle"
                            placeholder="Project Title"
                            value={project.projectTitle}
                            onChange={handleInputChange}
                        />
                    </Form.Field>
                    {['non_tech_business', 'tech_project', 'teachable_project'].includes(project.projectType) ? <Form.Field>
                        <label>Background</label>
                        <TextArea
                            placeholder='Project Background'
                            name="background"
                            value={project.background}
                            onChange={handleInputChange}
                        />
                    </Form.Field> : null}
                    {['non_tech_business', 'tech_project'].includes(project.projectType) ? <Form.Field>
                        <label>Features</label>
                        <List>
                            {project.features && project.features.map((feature, index) => (
                                <List.Item key={index}>
                                    <List.Content>
                                        <List.Header>
                                            {feature}
                                            <Icon name="delete" onClick={() => setProject(prev => ({
                                                ...prev,
                                                features: prev.features.filter((_, i) => i !== index)
                                            }))} />
                                        </List.Header>
                                    </List.Content>
                                </List.Item>
                            ))}
                        </List>
                        <TextArea
                            placeholder='Add new feature (hit Enter to commit feature)'
                            name="features"
                            value={taskForm.newFeature}
                            onChange={(e, { value }) => setTaskForm(prev => ({ ...prev, newFeature: value }))}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();  // Prevent the default form submit
                                    if (taskForm.newFeature.trim()) {
                                        setProject(prev => ({
                                            ...prev,
                                            features: [...prev.features, taskForm.newFeature.trim()]
                                        }));
                                        setTaskForm(prev => ({ ...prev, newFeature: '' })); // Clear the input after adding
                                    }
                                }
                            }}
                        />
                    </Form.Field> : null}
                    {(['teachable_project', 'study_group'].includes(project.projectType) && project.topics?.length)  ? <Form.Field>
                        <label>Topics</label>
                        {project.topics.map((topic, index) => (
                            <Label key={index} style={{ marginBottom: '5px' }}>
                            {topic}
                            </Label>
                        ))}
                        </Form.Field>
                        : null
                    }
                    {(['teachable_project', 'study_group'].includes(project.projectType) && project.prerequisites?.length)  ? <Form.Field>
                        <label>Prerequisites</label>
                        {project.prerequisites.map((prereq, index) => (
                            <Label key={index} style={{ marginBottom: '5px' }}>
                            {prereq}
                            </Label>
                        ))}
                        </Form.Field>
                        : null
                    }
                    {['tech_project'].includes(project.projectType) ? <Form.Field>
                        <label>Technologies</label>
                        <Dropdown
                            placeholder='Select Technologies'
                            fluid
                            multiple
                            selection
                            search
                            options={generateSkillOptions()}
                            value={project.technologies}
                            onChange={(e, { value }) => handleInputChange(e, { name: 'technologies', value })}
                        />
                    </Form.Field> : null}
                    {['teachable_project', 'tech_project'].includes(project.projectType) ?<Form.Field>
                        <label>Timeline</label>
                        <Dropdown
                            placeholder='Select Timeline'
                            fluid
                            selection
                            options={projectTimelineOptions()}
                            value={project.timeline}
                            onChange={(e, { value }) => handleInputChange(e, { name: 'timeline', value })}
                        />
                    </Form.Field> : null}
                    <Button onClick={handleSubmit} primary>Update Project</Button>
                </Form>
            </Segment>
            {(project.participants && project.participants.length)  ? 
                <Segment>
                    <Header as='h5'>Members</Header>
                    {formatParticipants(project.participants)}
                </Segment>
                : null}
            <Segment>
            {
                    !project.project_timeline ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2px' }}>
                        <Form.Input
                            type="date"
                            name="startDate"
                            label="Start Date"
                            value={taskForm.startDate}
                            onChange={handleChange}
                            style={{ flex: 1 }} // Optionally make the input flex to take necessary space
                        />
                        <Button loading={loading} disabled={loading} onClick={createAiTimeline}>
                            Create Plan with AI <Icon name="snowflake outline" />
                        </Button>
                    </div>
                     :
                    <>
                    <Label
                        style={{'margin': '2px'}}
                        color='olive'
                        size="large"
                    >
                        Start Date: {taskForm.startDate || 'Unavailable'}
                        <Icon name="edit" onClick={handleStartDateModalToggle} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                    </Label>
                    <Popup
                        style={{marginLeft: 'auto'}}
                        content={
                        <div>
                            <p>Update the start date as needed. The start and end dates of tasks will update automatically.</p>  
                        </div>
                        }
                        trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
                    />
                    <Modal open={startDateModalOpen} onClose={handleStartDateModalToggle} size="small">
                        <Modal.Header>Update Start Date</Modal.Header>
                        <Modal.Content>
                            <ReactDatePicker
                              selected={startDate}
                              id="startDatePicker"
                              onChange={handleStartDateChange}
                              dateFormat="yyyy-MM-dd"
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={handleStartDateModalToggle}>Cancel</Button>
                            <Button positive onClick={handleStartDateUpdateSubmit}>Save Changes</Button>
                        </Modal.Actions>
                    </Modal>
                    </>
                }
            </Segment>
            {
                !project.project_timeline ?
                <Segment loading={loading}>
                <Form>
                    <Header as='h5'>
                        Add Task(s)
                        <Popup
                            content={
                            <div>
                                <p>Tasks added will show up on the Timeline below. You can auto-magically create the tasks based on the background and features by clicking "Create Plan with AI". Make sure to Submit Timeline for project when done adding all tasks.</p>  
                            </div>
                            }
                            trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
                        />
                    </Header>
                    <Form.Input
                        type="text"
                        name="content"
                        placeholder="Task name"
                        value={taskForm.content}
                        onChange={handleChange}
                    />
                    <Form.Input
                        type="text"
                        name="description"
                        placeholder="Task description (optional)"
                        value={taskForm.description}
                        onChange={handleChange}
                    />
                    <Form.Input
                        type="number"
                        name="duration"
                        placeholder="Duration (days)"
                        value={taskForm.duration}
                        onChange={handleChange}
                    />
                    <Container>
                        <>
                        {tasks.length > 0 ? <p><strong>Select the task(s) this new task is dependent on. Leave all tasks unchecked if this task can be completed independently.</strong></p> : null}
                        {tasks.get().map(task => (
                            <Form.Field 
                                key={task.id}
                                style={{'margin': '2px'}}
                            >
                                <Checkbox
                                    label={task.content}
                                    name="dependencies"
                                    value={task.id}
                                    checked={taskForm.dependencies.includes(task.id)}
                                    onChange={handleChange}
                                />
                                <Label>
                                    {task.description}
                                </Label>
                            </Form.Field>
                        ))}
                        </>
                    </Container>
                    <Button disabled={!taskForm.content || !taskForm.duration} onClick={handleAddTask}>Add Task</Button>
                    </Form>
                    <Divider />
                    <Button disabled={!tasks.length} onClick={submitTimeline} color="green">Submit timeline for project</Button>
                </Segment>
                :
                <Segment>
                    <Header>Tasks for timeline:</Header>
                    <AddNewTaskForExistingTimelineForm projectTimelineId={project.project_timeline} refetchProjectDetails={fetchProjectDetails}/>
                    <List>
                        {tasks.map(task => (
                            <TaskItem
                                key={task._id}
                                task={task}
                                isCreatorOrParticipant={isCreatorOrParticipant}
                                isCreator={isCreator(project.project_creator)}
                                handleTaskStatusChange={handleTaskStatusChange}
                                handleAssigneeChange={handleAssigneeChange}
                                refetchProjectDetails={fetchProjectDetails}
                                teamMemberOptions={teamMemberOptions(project.participants)}
                                isCloned={project.cloned_from}
                            />
                        ))}
                    </List>
                    {
                    isCreator(project.project_creator) ? 
                        <Grid centered>
                            <Grid.Row>
                            <Grid.Column textAlign="center">
                                <ButtonGroup vertical >
                                    <Button loading={loading} disabled={loading} onClick={deleteTimeline} color="red">Delete timeline for Project</Button>
                                    <Button loading={loading} disabled={loading} primary onClick={cloneProject} color="red">Clone Project</Button>
                                    <Button loading={loading} disabled={loading} secondary onClick={cloneProjectWithApplications} color="red">Clone Project With Applications</Button>
                                    {project.status === 'recruiting' ? <Button loading={loading} disabled={loading} onClick={() => updateProjectStatus('in_progress')} color="green">Close Applications and Kick-off</Button> : null}
                                    {project.status === 'in_progress' ? <Button loading={loading} disabled={loading} onClick={() => updateProjectStatus('completed')} color="green">Complete Project</Button> : null}
                                    {['in_progress', 'completed'].includes(project.status) ? <Button loading={loading} disabled={loading} onClick={() => updateProjectStatus('archived')} color="yellow">Archive Project</Button> : null}
                                    {/* FIXME: Disabled Discord chat */}
                                    {/* {(!project.discord_invite_link && project.status === 'in_progress') ? <Button loading={loading} disabled={loading} onClick={() => createDiscordChat()} color="purple">Create Discord Chat</Button> : null} */}
                                </ButtonGroup>
                            </Grid.Column>
                            </Grid.Row>
                        </Grid> : null
                    }
                </Segment>
            }
            {isCreator(project.project_creator) ? <Segment>
                <Grid centered>
                    <Grid.Row>
                    <Grid.Column textAlign="center">
                        <Button primary onClick={handleProjectApplicationsModalToggle}>
                        View Project Applications
                        </Button>
                    </Grid.Column>
                    </Grid.Row>
                </Grid>
                <ProjectApplicationsForProject
                    projectId={project._id}
                    open={projectApplicationsModalOpen}
                    onClose={handleProjectApplicationsModalToggle}
                />
            </Segment> : null}            
        </Segment.Group>
    );
};

export default TaskTimeline;
