import React, { useState, useEffect } from 'react';
import { Table, Pagination, Button, Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const AdminProjectsTable = () => {
    const [projects, setProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [onlyUnapproved, setOnlyUnapproved] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const queryParams = new URLSearchParams({
                page: currentPage,
                limit: 10,
                onlyUnapproved: onlyUnapproved
            });

            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/admin/projects?${queryParams}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    },
                }
            );
            const data = await response.json();
            setProjects(data.projects);
            setTotalPages(data.totalPages);
        };

        fetchData();
    }, [currentPage, onlyUnapproved]);

    const handlePaginationChange = (e, { activePage }) => {
        setCurrentPage(activePage);
    };

    const handleApprove = async (projectId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/admin/projects/${projectId}/approve`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            if (response.ok) {
                toast.success('Project approved successfully');
                setProjects(projects.map(project => project._id === projectId ? { ...project, approved: true } : project));
            } else {
                toast.error('Failed to approve project');
            }
        } catch (error) {
            toast.error('An error occurred while approving the project');
        }
    };

    return (
        <div>
            <Checkbox
                label="Show only unapproved projects"
                checked={onlyUnapproved}
                onChange={() => setOnlyUnapproved(!onlyUnapproved)}
                style={{ marginBottom: '10px' }}
            />
            <Table celled sortable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Creator</Table.HeaderCell>
                        <Table.HeaderCell>Project Title</Table.HeaderCell>
                        <Table.HeaderCell>Project Type</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {projects.map((project, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>
                                {project.project_creator ? <Link to={`/users/${project.project_creator._id}`}>{project.project_creator.fullname}</Link> : null}
                            </Table.Cell>
                            <Table.Cell>
                                <Link to={`/projects/${project._id}`}>{project.projectTitle}</Link>
                            </Table.Cell>
                            <Table.Cell>{project.projectType}</Table.Cell>
                            <Table.Cell>
                                <Button
                                    positive
                                    onClick={() => handleApprove(project._id)}
                                    disabled={project.approved}
                                >
                                    Approve
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='4'>
                            <Pagination
                                totalPages={totalPages}
                                activePage={currentPage}
                                onPageChange={handlePaginationChange}
                                secondary
                                fluid
                                style={{justifyContent: 'center'}}
                            />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    );
};

export default AdminProjectsTable;
