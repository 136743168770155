import React, { useEffect, useState } from 'react';
import { Button, Card, Header, Icon, Loader, Message } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const CertificateList = ({ certificateIds }) => {
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleButtonClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  useEffect(() => {
    if (certificateIds.length > 0) {
      const fetchCertificates = async () => {
        setLoading(true);
        setError(null);

        try {
          // Replace with your backend endpoint
          const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/users/certificates`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: JSON.stringify({ certificateIds })
          });

          if (!response.ok) throw new Error('Failed to fetch certificates');
          const data = await response.json();
          setCertificates(data.certificates);
        } catch (err) {
          console.error('Error fetching certificates:', err);
          setError(err.message);
          toast.error('Error loading certificates');
        } finally {
          setLoading(false);
        }
      };

      fetchCertificates();
    }
  }, [certificateIds]);

  return (
    <div>
      {loading && <Loader active inline="centered" content="Loading certificates..." />}
      {error && <Message error header="Error" content={error} />}
        <Header as="h4">Certifications:</Header>
      <Card.Group>
        {certificates.map((certificate) => (
          <Card key={certificate.certificateId} fluid>
            <Card.Content>
              <Card.Description><Icon name={'trophy'} color='yellow'/><strong>{certificate.courseTitle}</strong></Card.Description>
              <Card.Meta>Issued: {moment(certificate.issuedAt).format('MMMM Do, YYYY')}</Card.Meta>
              <Card.Description>
                Certificate ID: <i>{certificate.certificateId}</i>
              </Card.Description>
              <Button basic size="small" onClick={() => handleButtonClick(certificate.project)} style={{ marginTop: '1rem' }}>
                Go to Course
                </Button>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </div>
  );
};

export default CertificateList;
