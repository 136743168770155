import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Generic wrapper component to check for authentication
const RequireAuth = (Component) => {
  return function AuthWrapper(props) {
    const { isLoggedIn } = props; // Destructure isLoggedIn from props
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (!isLoggedIn) {
        // Redirect to the login page with a `redirect` query parameter for the original URL
        navigate(`/login?redirect=${encodeURIComponent(location.pathname)}`);
      }
    }, [isLoggedIn, navigate, location]);

    // Render the wrapped component if the user is logged in
    return isLoggedIn ? <Component {...props} /> : null;
  };
};

export default RequireAuth;
