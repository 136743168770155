import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Dropdown, TextArea, Label, Icon, Popup, Checkbox } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { addOption } from '../optionsHelpers';
import { checkTokenValidity } from '../common_frontend_requests';
import { useNavigate } from 'react-router-dom';
import { consolidatedOptions, generateSkillOptions, getCountryOptions, projectTimelineOptions } from '../displayHelpers';
import CallToActionBanner from './CallToActionBanner';
import TopicInput from './TopicInput'; // Assuming you have this component

const MAX_FEATURES = 10;

const FeatureInput = ({ onAddFeature, features }) => {
  const [inputValue, setInputValue] = useState('');

  const handleAddFeature = () => {
    if (inputValue && features.length < MAX_FEATURES) {
      onAddFeature(inputValue);
      setInputValue(''); // Clear input after adding
    }
  };

  return (
    <Form.Field>
      <input
        placeholder="Enter a feature (hit Enter to commit)"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleAddFeature()}
      />
    </Form.Field>
  );
};

const VetApplicationsCheckbox = ({vetApplications, setFormValues}) => {
  return(
    <Form.Field>
      <span>
        <Checkbox
            label="Vet Applications"
            checked={vetApplications}
            onChange={() => setFormValues((prevValues) => ({ ...prevValues, vetApplications: !vetApplications }))}
            style={{ marginBottom: '10px' }}
        />
        <Popup
            content={
            <div>
                <p>Check this box if you want to view each application to your project and manually vet them. If unchecked, applications will be automatically approved by the system.</p>  
            </div>
            }
            trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
        />
      </span>
    </Form.Field>
  )
}

const ProjectModal = ({ isOpen, onClose, forCourses=false }) => {
  const [features, setFeatures] = useState([]);
  const [topics, setTopics] = useState([]);
  const [prerequisites, setPrerequisites] = useState([]);
  const [formValues, setFormValues] = useState({
    projectTitle: '',
    projectType: forCourses ? 'teachable_project' : '',
    domain: '',
    timeline: '',
    technologies: [],
    teamSize: '',
    background: '',
    courseLink: '',
    courseTitle: '',
    country: '',
    vetApplications: false,
    allowAnyoneToUse: false
  });
  const [currentScreen, setCurrentScreen] = useState(1);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [technologyOptions, setTechnologyOptions] = useState(generateSkillOptions())
  const navigate = useNavigate();
  const [hasResume, setHasResume] = useState(null);

  const fetchIndustryOptions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/options/industry`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      const data = await response.json();
      setIndustryOptions(data);
    } catch (error) {
      console.error('Failed to fetch industry options:', error);
    }
  };

  const checkResume = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/resume/hasResume`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      });
      const data = await response.json();
      setHasResume(data.found);
    } catch (error) {
      console.error('Error fetching resume:', error);
    }
  };

  useEffect(() => {
    fetchIndustryOptions();
    checkResume();
  }, []);

  const handleFormSubmit = async () => {
    const projectData = {
      ...formValues,
      features,
      topics,
      prerequisites
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify(projectData),
      });
      if (response.ok) {
        const responseData = await response.json();
        toast.success("Successfully added project! You may now view the project.");
        onClose();
        navigate(`/projects/${responseData._id}`);
      } else {
        toast.error("There was an error submitting the project");
        console.error('Error submitting project:', response.statusText);
      }
    } catch (error) {
      toast.error("There was an error submitting the project");
      console.error('Error submitting project:', error);
    }
  };

  const handleDropdownChange = (e, { name, value }) => {
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const addFeature = (feature) => {
    setFeatures((prev) => [...prev, feature]);
  };

  const handleIndustryAdd = async (e, { value }) => {
    await addOption('industry', value);
    await fetchIndustryOptions();
  };

  const removeFeature = (indexToRemove) => {
    setFeatures((prev) => prev.filter((_, index) => index !== indexToRemove));
  };

  const handleAddTopic = (topic) => {
    setTopics([...topics, topic]);
  };

  const handleRemoveTopic = (index) => {
    setTopics(topics.filter((_, i) => i !== index));
  };

  const handleAddPrerequisite = (prerequisite) => {
    setPrerequisites([...prerequisites, prerequisite]);
  };

  const handleRemovePrerequisite = (index) => {
    setPrerequisites(prerequisites.filter((_, i) => i !== index));
  };

  const renderFirstScreen = () => (
    <>
      <Form.Field>
        <label>Project Title</label>
        <input
          placeholder="Enter project title"
          value={formValues.projectTitle}
          onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, projectTitle: e.target.value }))}
        />
      </Form.Field>
      <Form.Field>
        <label>Project Type</label>
        <Dropdown
          placeholder='Select Project Type'
          fluid
          selection
          disabled={forCourses}
          name="projectType"
          options={[
            { key: 'teachable_project', text: 'Crash Courses', value: 'teachable_project' },
            { key: 'study_group', text: 'Study Group', value: 'study_group' },
            { key: 'non_tech_business', text: 'Non-Tech Business', value: 'non_tech_business' },
            { key: 'tech_project', text: 'Tech Project', value: 'tech_project' },
          ]}
          onChange={handleDropdownChange}
          value={forCourses ? 'teachable_project' : formValues.projectType}
        />
      </Form.Field>
    </>
  );

  const handleAddition = (e, { value }) => {
    // Add the new option to the options array
    setTechnologyOptions(prevOptions => [...prevOptions, { key: value, text: value, value }]);
    addOption('skill', value)
  };

  const renderSecondScreen = () => {
    switch (formValues.projectType) {
      case 'teachable_project':
        return (
          <>
            <Form.Field>
              <label>Background</label>
              <TextArea
                placeholder='Project Background'
                name="background"
                onChange={(e, { value }) => setFormValues((prevValues) => ({ ...prevValues, background: value }))}
              />
            </Form.Field>
            <Form.Field>
                <span>
                    <Dropdown
                        style={{marginTop: 2}}
                        placeholder="Select Timeline"
                        selection
                        name="timeline"
                        options={projectTimelineOptions()}
                        value={formValues.timeline}
                        onChange={handleDropdownChange}
                    />
                    <Popup
                        content={
                        <div>
                            <p>Select the expected duration this project should take to complete.</p>  
                        </div>
                        }
                        trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
                    />
                </span>
            </Form.Field>
            <Form.Field>
              <label>Topics</label>
              <TopicInput onAddTopic={handleAddTopic} topics={topics} />
              <div>
                {topics.map((topic, index) => (
                  <Label key={index} style={{ marginBottom: '5px' }}>
                    {topic}
                    <Icon name="delete" onClick={() => handleRemoveTopic(index)} />
                  </Label>
                ))}
              </div>
            </Form.Field>
            <Form.Field>
              <label>Prerequisites</label>
              <TopicInput onAddTopic={handleAddPrerequisite} topics={prerequisites} />
              <div>
                {prerequisites.map((prerequisite, index) => (
                  <Label key={index} style={{ marginBottom: '5px' }}>
                    {prerequisite}
                    <Icon name="delete" onClick={() => handleRemovePrerequisite(index)} />
                  </Label>
                ))}
              </div>
            </Form.Field>
          </>
        );
      case 'study_group':
        return (
          <>
            <Form.Field>
              <label>Course Link</label>
              <input
                placeholder="Enter course link"
                value={formValues.courseLink}
                onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, courseLink: e.target.value }))}
              />
            </Form.Field>
            <Form.Field>
              <label>Course Title</label>
              <input
                placeholder="Enter course title"
                value={formValues.courseTitle}
                onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, courseTitle: e.target.value }))}
              />
            </Form.Field>
            <Form.Field>
              <label>Topics</label>
              <TopicInput onAddTopic={handleAddTopic} topics={topics} />
              <div>
                {topics.map((topic, index) => (
                  <Label key={index} style={{ marginBottom: '5px' }}>
                    {topic}
                    <Icon name="delete" onClick={() => handleRemoveTopic(index)} />
                  </Label>
                ))}
              </div>
            </Form.Field>
            <Form.Field>
              <label>Prerequisites</label>
              <TopicInput onAddTopic={handleAddPrerequisite} topics={prerequisites} />
              <div>
                {prerequisites.map((prerequisite, index) => (
                  <Label key={index} style={{ marginBottom: '5px' }}>
                    {prerequisite}
                    <Icon name="delete" onClick={() => handleRemovePrerequisite(index)} />
                  </Label>
                ))}
              </div>
            </Form.Field>
            <VetApplicationsCheckbox vetApplications={formValues.vetApplications} setFormValues={setFormValues}/>
          </>
        );
      case 'non_tech_business':
        return (
          <>
            <Form.Field>
              <label>Background</label>
              <TextArea
                placeholder='Project Background'
                name="background"
                onChange={(e, { value }) => setFormValues((prevValues) => ({ ...prevValues, background: value }))}
              />
            </Form.Field>
            <Form.Field>
              <label>Domain</label>
              <Dropdown
                placeholder='Select Domain'
                fluid
                selection
                name="domain"
                options={industryOptions}
                onChange={handleDropdownChange}
                allowAdditions={true}
                onAddItem={handleIndustryAdd}
                search
              />
            </Form.Field>
            <Form.Field>
                <span>
                    <Dropdown
                        label="Country"
                        options={getCountryOptions()}
                        search
                        selection
                        placeholder="Select Country"
                        name="country"
                        value={formValues.country}
                        onChange={handleDropdownChange}
                    />
                    <Popup
                        content={
                        <div>
                            <p>Choose the country the project will be based out of. This country should typically be the location where the solution is implemented, not necessarily where the team members will be located.</p>  
                        </div>
                        }
                        trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
                    />
                </span>
            </Form.Field>
            <Form.Field>
              <label>Features</label>
              <FeatureInput onAddFeature={addFeature} features={features} />
              {features.map((feature, index) => (
                <Label key={index}>
                  {feature}
                  <Icon name="delete" onClick={() => removeFeature(index)} />
                </Label>
              ))}
            </Form.Field>
            <VetApplicationsCheckbox vetApplications={formValues.vetApplications} setFormValues={setFormValues}/>
          </>
        );
      case 'tech_project':
        return (
          <>
            <Form.Field>
              <label>Background</label>
              <TextArea
                placeholder='Project Background'
                name="background"
                onChange={(e, { value }) => setFormValues((prevValues) => ({ ...prevValues, background: value }))}
              />
            </Form.Field>
            <Form.Field>
                <span>
                    <Dropdown
                        style={{marginTop: 2}}
                        placeholder="Select Timeline"
                        selection
                        name="timeline"
                        options={projectTimelineOptions()}
                        value={formValues.timeline}
                        onChange={handleDropdownChange}
                    />
                    <Popup
                        content={
                        <div>
                            <p>Select the expected duration this project should take to complete.</p>  
                        </div>
                        }
                        trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
                    />
                </span>
            </Form.Field>
            <Form.Field>
            <span>
                <Dropdown
                    style={{marginTop: 2}}
                    placeholder="Select Technologies"
                    search
                    multiple
                    selection
                    name="technologies"
                    allowAdditions={true}
                    onAddItem={handleAddition}
                    options={consolidatedOptions(technologyOptions, formValues.technologies)}
                    value={formValues.technologies}
                    onChange={handleDropdownChange}
                />
                <Popup
                    content={
                    <div>
                        <p>Select (or add) the technologies that you plan to build this project with. Your selection is not binding but will help you find the right candidates.</p>  
                    </div>
                    }
                    trigger={<Icon name='info circle' style={{ marginLeft: '5px', cursor: 'pointer' }} />}
                />
            </span>
            </Form.Field>
            <Form.Field>
              <label>Domain</label>
              <Dropdown
                placeholder='Select Domain'
                fluid
                selection
                name="domain"
                options={industryOptions}
                onChange={handleDropdownChange}
                allowAdditions={true}
                onAddItem={handleIndustryAdd}
                search
              />
            </Form.Field>
            <Form.Field>
              <label>Features</label>
              <FeatureInput onAddFeature={addFeature} features={features} />
              {features.map((feature, index) => (
                <Label key={index}>
                  {feature}
                  <Icon name="delete" onClick={() => removeFeature(index)} />
                </Label>
              ))}
            </Form.Field>
            <VetApplicationsCheckbox vetApplications={formValues.vetApplications} setFormValues={setFormValues}/>
          </>
        );
      default:
        return null;
    }
  };

  const nextScreen = () => setCurrentScreen((prev) => prev + 1);
  const prevScreen = () => setCurrentScreen((prev) => prev - 1);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Add Project</Modal.Header>
      <Modal.Content>
        {!hasResume && (
          <CallToActionBanner 
            message={'Your chances of getting approved for a project are much higher if we have your resume. Projects can only recruit once they have been approved.'} 
            buttonLink={'/profile'} 
            buttonText={'Add Resume'}
          />
        )}
        <Form>
          {currentScreen === 1 && renderFirstScreen()}
          {currentScreen === 2 && renderSecondScreen()}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {currentScreen > 1 && <Button onClick={prevScreen}>Back</Button>}
        {currentScreen < 2 && <Button onClick={nextScreen} positive disabled={!formValues.projectTitle || !formValues.projectType}>Next</Button>}
        {currentScreen === 2 && <Button positive onClick={handleFormSubmit}>Submit</Button>}
      </Modal.Actions>
    </Modal>
  );
};

const AddProject = ({forCourses}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const validateToken = async () => {
    const data = await checkTokenValidity();
    setIsLoggedIn(data.isValid);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    validateToken();
  }, []);

  return (
    isLoggedIn && (
      <>
        <ProjectModal isOpen={isModalOpen} onClose={closeModal} forCourses={forCourses}/>
        <Button fluid primary onClick={openModal}>Add {forCourses ? 'Course' : 'Project'}</Button>
      </>
    )
  );
};

export default AddProject;
