import React, { useState, useEffect } from 'react';
import { Card, Dropdown, Pagination, Segment, Menu, Grid, Message, Button, Image } from 'semantic-ui-react';
import { mapReferralOptionToDisplay, createLabel, withNoSelection } from '../displayHelpers';
import { Link } from 'react-router-dom';

const UsersList = () => {
    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [stances, setStances] = useState([]);
    const [filters, setFilters] = useState({
        company: '',
        stance: '',
        page: 1
    });
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const fetchFilters = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/users/filters`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    },
                });

                if (!response.ok) throw new Error('failed to fetch filters');

                const data = await response.json();
                setCompanies(data.companies);
                setStances(data.stances);
            } catch (error) {
                console.error('Error validating token:', error);
                return false;
            }
        };
        fetchFilters();
        fetchUsers();
    }, []);

    useEffect(() => {
        fetchUsers();
    }, [filters]);

    const fetchUsers = async () => {
        const { company, stance, page } = filters;
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/users/search?company=${company}&stance=${stance}&page=${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
        });
        const data = await res.json();
        setUsers(data.users);
        setTotalPages(data.totalPages);
    };

    const handleFilterChange = (e, { name, value }) => {
        setFilters({ ...filters, [name]: value });
    };

    const handlePageChange = (e, { activePage }) => {
        setFilters({ ...filters, page: activePage });
    };

    return (
        <Segment>
            <Grid padded>
                <Grid.Row>
                    <Menu>
                        <Menu.Item header>
                            Filters
                        </Menu.Item>
                        <Dropdown
                            style={{ margin: 10 }}
                            placeholder="Select Company"
                            fluid
                            selection
                            options={
                                withNoSelection(
                                    companies.map(company => ({
                                        key: company,
                                        text: company,
                                        value: company
                                    }))
                                )
                            }
                            onChange={(e, data) => handleFilterChange(e, { name: 'company', value: data.value })}
                            value={filters.company}
                        />
                        <Dropdown
                            style={{ margin: 10 }}
                            placeholder="Select Referral Stance"
                            fluid
                            selection
                            options={withNoSelection(
                                stances.map(stance => ({
                                    key: stance,
                                    text: mapReferralOptionToDisplay(stance),
                                    value: stance
                                }))
                            )}
                            onChange={(e, data) => handleFilterChange(e, { name: 'stance', value: data.value })}
                            value={filters.stance}
                        />
                    </Menu>
                </Grid.Row>
                <Grid.Row>
                    {users.length > 0 ? (
                        <Card.Group>
                            {users.map((user, index) => (
                                <Card key={user._id}>
                                    {/* Conditionally show profile image if available */}
                                    {user.profileImage && (
                                        <Image circular size="tiny" src={user.profileImage} wrapped ui={false} />
                                    )}
                                    <Card.Content>
                                        <Card.Header>{user.fullname}</Card.Header>
                                        <Card.Meta>{user.current_company}</Card.Meta>
                                        {user.institution_labels && user.institution_labels.length ? (
                                            <Card.Meta>
                                                {user.institution_labels.map(ins => createLabel('blue', ins, 'graduation cap'))}
                                            </Card.Meta>
                                        ) : null}
                                        <Card.Description>
                                            {mapReferralOptionToDisplay(user.referral_stance)}
                                        </Card.Description>
                                    </Card.Content>
                                    <Card.Content extra>
                                        <Link to={`/users/${user._id}`}>
                                            <Button>See full profile</Button>
                                        </Link>
                                    </Card.Content>
                                </Card>
                            ))}
                        </Card.Group>
                    ) : (
                        <Message info>
                            <Message.Header>No Users Found</Message.Header>
                            <p>No users match the selected criteria. Please adjust the filters to view more users.</p>
                        </Message>
                    )}
                </Grid.Row>
                <Grid.Row>
                    <Pagination
                        defaultActivePage={1}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        secondary
                        fluid
                        style={{justifyContent: 'center'}}
                    />
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default UsersList;
