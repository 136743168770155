import React, { useState } from 'react';
import { Modal, Button, Message } from 'semantic-ui-react';
import Resume from './Resume';

const ResumeModal = ({ userId, triggerButton }) => {
  const [resumeModalOpen, setResumeModalOpen] = useState(false);
  const [selectedResume, setSelectedResume] = useState(null);
  const [resumeError, setResumeError] = useState(null);

  const fetchResume = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/resume/get_one/${userId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        }
      );
      if (response.status === 404) {
        setResumeError('User has not provided a resume yet.');
        setSelectedResume(null);
      } else if (!response.ok) {
        throw new Error('Failed to fetch resume');
      } else {
        const data = await response.json();
        setSelectedResume(data);
        setResumeError(null);
      }
    } catch (error) {
      console.error('Error fetching resume:', error);
      setResumeError('Failed to fetch resume');
      setSelectedResume(null);
    }
  };

  const openModal = async () => {
    await fetchResume();
    setResumeModalOpen(true);
  };

  return (
    <>
      <span onClick={openModal}>
        {triggerButton}
      </span>
      <Modal
        open={resumeModalOpen}
        onClose={() => setResumeModalOpen(false)}
        size='large'
      >
        <Modal.Header>Resume</Modal.Header>
        <Modal.Content>
          {resumeError ? (
            <Message info>{resumeError}</Message>
          ) : selectedResume ? (
            <Resume resume={selectedResume} />
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setResumeModalOpen(false)}>Close</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ResumeModal;
