import React, { useState } from 'react';
import { Menu, Segment } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import UsersList from './UsersList'; // Import the Users list component
import CompactResumesList from './CompactResumesList'; // Import the CompactResumes list component

const UsersAndResumes = ({ isLoggedIn }) => {
  const [activeTab, setActiveTab] = useState('resumes');
  const navigate = useNavigate(); // Initialize navigate function

  // Updated handleTabClick to navigate to new routes
  const handleTabClick = (e, { name }) => {
    setActiveTab(name);
    navigate(`/${name}`); // Navigate to the respective route
  };

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case 'users':
        return <UsersList />; // Render the UsersList component
      case 'resumes':
        return <CompactResumesList />; // Render the CompactResumesList component
      default:
        return null;
    }
  };

  return (
    isLoggedIn ? (
      <Segment>
        <Menu attached="top" tabular>
          <Menu.Item
            name="resumes"
            active={activeTab === 'resumes'}
            onClick={handleTabClick}
          >
            Resumes
          </Menu.Item>
          <Menu.Item
          name="users"
            active={activeTab === 'users'}
            onClick={handleTabClick}
          >
            Users
          </Menu.Item>
        </Menu>
        <Segment attached="bottom">
          {renderActiveTabContent()}
        </Segment>
      </Segment>
    ) : null
  );
};

export default UsersAndResumes;
