import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Button, Label, Popup, Icon } from 'semantic-ui-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { createLabel } from '../displayHelpers';

const Navbar = ({ isLoggedIn, roles, handleRoleUpdate, membershipData }) => {
  const [role, setRole] = useState(null); // Example roles: Admin, User, Guest
  const location = useLocation(); // Get the current location
  const [activeItem, setActiveItem] = useState(location.pathname); // Set the active item based on the current path
  const [isMobile, setIsMobile] = useState(false); // State to detect mobile view

  useEffect(() => {
    setActiveItem(location.pathname); // Update active item when the location changes
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set mobile mode if screen width is less than or equal to 768px
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const hasAdminRole = () => roles.includes('admin');

  useEffect(() => {
    if (roles && roles.length > 0) {
      setRole(roles[0]); // Set initial role or update role when roles prop changes
    }
  }, [roles]); // This effect depends on the roles prop

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    navigate('/login');
    window.location.reload();
  };

  return (
    <Menu sticky="top" secondary size="large" className={isMobile ? 'mobile-navbar' : ''}>
      {isLoggedIn ? (
        <>
          {/* Left-aligned items */}
          <Menu.Menu position="left">
            <Menu.Item as={Link} to="/" active={activeItem === '/'} style={{ padding: '10px 20px', border: 'none' }}>
              <img src={require('../assets/logo.png')} alt="Logo" style={{ height: (isMobile ? '56px' : '80px'), width: (isMobile ? '105px' : '150px'), background: 'rgba(0,0,0,0)' }} />
              {!isMobile && (
                <Label color="blue" circular size="mini" style={{ position: 'absolute', bottom: 0, right: 0, transform: 'translate(-50%, -50%)' }}>
                  BETA
                </Label>
              )}
            </Menu.Item>
          </Menu.Menu>

          {/* Right-aligned items */}
          <Menu.Menu position="right">
            <Menu.Item>
              {membershipData.membership_type === 'pro' ? createLabel('purple', isMobile ? `${membershipData.ai_credits}` : 'PRO', 'shield') : null}
              {!isMobile ? <Popup
                content={
                  <div>
                    <p>Number of AI credits remaining. Use can use AI credits to auto-magically create project timelines.</p>
                  </div>
                }
                trigger={createLabel('pink', `${membershipData.ai_credits}`, 'magic')}
              /> : null}
            </Menu.Item>

            {hasAdminRole() && !isMobile && (
              <Dropdown item text={isMobile ? <Icon name="user circle" /> : `Role: ${role}`}>
                <Dropdown.Menu>
                  {roles.map((roleItem, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        setRole(roleItem);
                        handleRoleUpdate(roleItem);
                      }}
                    >
                      {roleItem}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}

            <Menu.Item as={Link} to="/account">
              {isMobile ? <Icon style={{ margin: '-10px' }} name="settings" /> : 'Account'}
            </Menu.Item>
            {!isMobile ? <Menu.Item as={Link} to="/pricing">
              Pricing
            </Menu.Item> : null}
            <Menu.Item>
              <Button style={{ margin: (isMobile ? '0' : '-10px') }} primary onClick={handleLogout}>{isMobile ? <Icon name="sign-out" /> : 'Log Out'}</Button>
            </Menu.Item>
          </Menu.Menu>
        </>
      ) : (
        <>
          {/* Left-aligned items */}
          <Menu.Menu position="left">
            <Menu.Item as={Link} to="/" active={activeItem === '/'} style={{ padding: '10px 20px', border: 'none' }}>
            <img src={require('../assets/logo.png')} alt="Logo" style={{ height: (isMobile ? '56px' : '80px'), width: (isMobile ? '105px' : '150px'), background: 'rgba(0,0,0,0)' }} />
              {!isMobile && (
                <Label color="blue" circular size="mini" style={{ position: 'absolute', bottom: 0, right: 0, transform: 'translate(-50%, -50%)' }}>
                  BETA
                </Label>
              )}
            </Menu.Item>
          </Menu.Menu>

          {/* Right-aligned items */}
          <Menu.Menu position="right">
            {!isMobile ? <Menu.Item as={Link} to="/about" active={activeItem === '/about'}>
              About
            </Menu.Item> : null}
            <Menu.Item as={Link} to="/login" active={activeItem === '/login'}>
              Log In
            </Menu.Item>
            <Menu.Item as={Link} to="/register" active={activeItem === '/register'}>
              Register
            </Menu.Item>
            {!isMobile ? <Menu.Item as={Link} to="/pricing">
              Pricing
            </Menu.Item> : null}
          </Menu.Menu>
        </>
      )}
    </Menu>
  );
};

export default Navbar;
