import React, { useState } from 'react';
import { Modal, Button, Segment } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

const CourseTaskReviewComponent = ({ task }) => {
    const [isOpen, setIsOpen] = useState(false);

    // Function to open the modal
    const openModal = () => {
        setIsOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsOpen(false);
    };

    // Combine the unchunked contents
    const combinedContent = [
        task?.description,
        task?.courseTask?.additional_context_material,
        task?.courseTask?.topics_material
    ].filter(Boolean).join('\n\n'); // Join with two newlines for better formatting

    return (
        <>
            <Button basic size="tiny" style={{ marginTop: '5px' }} onClick={openModal} primary>
                Review Content
            </Button>

            <Modal open={isOpen} onClose={closeModal} size="large" closeIcon>
                <Modal.Header>{task.content}</Modal.Header>
                <Modal.Content scrolling>
                    <Segment>
                        <ReactMarkdown>{combinedContent}</ReactMarkdown>
                    </Segment>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeModal} primary>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default CourseTaskReviewComponent;
