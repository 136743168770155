import React, { useState, useEffect } from 'react';
import { Feed, Icon, Form, Grid, TextArea, Button, Loader, Divider } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import moment from 'moment';

const ForumFeed = ({ courseTaskId }) => {
  const [forum, setForum] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch forum and comments by courseTaskId
  useEffect(() => {
    if (courseTaskId) {
      const fetchForumData = async () => {
        setLoading(true);
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/forum/${courseTaskId}`);
          if (!response.ok) throw new Error('Failed to fetch forum data');
          
          const data = await response.json();
          setForum(data.forum);
          setComments(data.forum?.comments || []);
        } catch (err) {
          toast.error(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchForumData();
    }
  }, [courseTaskId]);

  // Handle new comment change
  const handleNewCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  // Post a new comment
  const handlePostComment = async () => {
    if (!newComment.trim()) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/tasks/forum/${courseTaskId}/comment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ comment: newComment }),
      });
      
      if (!response.ok) throw new Error('Failed to post comment');

      const newCommentData = await response.json();
      setComments([...comments, newCommentData.comment]);
      setNewComment('');
      toast.success('Comment added successfully');
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div>
      {loading && <Loader active inline="centered" content="Loading forum and comments..." />}
        <Divider/ >
      <Feed size="small" style={{margin: '2px'}}>
        {comments.map((comment) => (
          <Feed.Event key={comment._id}>
            <Feed.Label>
              <Icon size="large" name="user" />
            </Feed.Label>
            <Feed.Content>
              <Feed.Summary>
                <Feed.User>{comment.userString}</Feed.User>
                <Feed.Date>{moment(comment.createdAt).fromNow()}</Feed.Date>
              </Feed.Summary>
              <Feed.Extra text>{comment.comment}</Feed.Extra>
            </Feed.Content>
          </Feed.Event>
        ))}
      </Feed>

      <Form>
        <Grid padded>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              <Form.Field>
                <TextArea
                  value={newComment}
                  onChange={handleNewCommentChange}
                  placeholder="Add your comments..."
                />
              </Form.Field>
              <Button fluid primary onClick={handlePostComment}>
                Add Comment
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
};

export default ForumFeed;
