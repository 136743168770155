import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, Form, TextArea, Segment, Label, Icon } from 'semantic-ui-react';

// Define the chat bubble styles
const messageStyles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    messageContainer: {
      display: 'flex',
      justifyContent: 'flex-start',  // Default to aligning to the left
      marginBottom: '10px',  // Add spacing between messages
      width: '100%',
    },
    fromUser: {
      backgroundColor: '#d1e7dd',
      margin: '10px 10px 10px 0px',
      padding: '10px',
      borderRadius: '10px',
      maxWidth: '70%',
      wordBreak: 'break-word',
    },
    fromOther: {
      backgroundColor: '#fefcbf',
      margin: '10px 10px 10px 30px',
      padding: '10px',
      borderRadius: '10px',
      maxWidth: '70%',
      wordBreak: 'break-word',
    },
  };

const GroupChatModal = ({ isOpen, onClose, projectId, viewingUser }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/${projectId}/messages`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        const messages = await response.json();
        setMessages(messages);
      } catch (error) {
        console.error('Failed to fetch messages:', error);
      }
    };

    if (isOpen) {
      fetchMessages();
    }
  }, [isOpen, projectId]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/projects/${projectId}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          content: newMessage.trim(),
          sender: viewingUser,
        }),
      });

      if (!response.ok) {
        console.error('Failed to send message.');
        toast.error('Failed to send message.');
        return;
      }

      const updatedMessages = await response.json();
      setMessages(updatedMessages);
      setNewMessage('');  // Clear the message input field

    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose} size="large">
        <Modal.Header>
            Group Chat
            <Icon name='close' onClick={onClose} style={{ cursor: 'pointer', float: 'right' }} />
        </Modal.Header>
      <Modal.Content>
        <Segment style={{ height: '400px', overflowY: 'scroll' }}>
          <div style={messageStyles.container}>
            {messages.map((msg) => (
              <div
                key={msg._id}
                style={{
                  ...messageStyles.messageContainer,
                  justifyContent: msg.sender === viewingUser ? 'flex-end' : 'flex-start'
                }}
              >
                <div
                  style={msg.sender._id === viewingUser ? messageStyles.fromUser : messageStyles.fromOther}
                >
                  <Label>
                    {msg.sender.fullname} - {new Date(msg.timestamp).toLocaleDateString('en-US', {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'})}
                  </Label>
                  <p>{msg.content}</p>
                </div>
              </div>
            ))}
          </div>
        </Segment>
        <Form>
          <Form.Field>
            <TextArea
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
          </Form.Field>
          <Button primary onClick={handleSendMessage}>
            Send
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default GroupChatModal;
