import React from 'react';
import { Segment, Label, Icon, Message, Button } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

const CompactResumeView = ({ resume }) => {
  const navigate = useNavigate(); // Initialize navigate function

  // Function to handle navigation to the ResumeForReviewPage
  const handleViewFullResume = () => {
    navigate(`/resumes/${resume._id}`); // Navigate to ResumeForReviewPage with the resume_id
  };

  // If the resume is not available, display an error message
  if (!resume) {
    return <Message error content="Resume not available." />;
  }

  return (
    <>
      {/* First Segment: Display job title, education, and overall summary */}
      <Segment>
        {resume.viewers ? <Label><Icon name="eye"/>{resume.viewers.length}</Label> : null}
        {resume.workExperiences?.length > 0 && (
          <Label style={{ margin: '5px 0' }} color="teal">
            <Icon name="building" /> {resume.workExperiences[0]?.jobTitle}
            <Label.Detail>{resume.workExperiences[0]?.company}</Label.Detail>
          </Label>
        )}
        &nbsp;
        {resume.education?.length > 0 && (
          <Label style={{ margin: '5px 0' }} color="teal">
            <Icon name="graduation" /> {resume.education[0]?.institution}
            <Label.Detail>{resume.education[0]?.degree}</Label.Detail>
          </Label>
        )}
        <br />
        {resume.overallSummary && <Message>{resume.overallSummary}</Message>}
      </Segment>

      {/* Second Segment: Show a "See Full Resume" button */}
      <Segment textAlign="center">
        <Button primary onClick={handleViewFullResume}>
          See Full Resume
        </Button>
      </Segment>
    </>
  );
};

export default CompactResumeView;
