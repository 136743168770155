import React, { useState, useEffect } from 'react';
import { Container, Segment, Header, List, ListItem, ListIcon, ListContent, Transition, Card } from 'semantic-ui-react';

const LandingPage = () => {
  const [isVisibleMission, setIsVisibleMission] = useState(false);
  const [isVisibleOfferings, setIsVisibleOfferings] = useState(false); // State to control the visibility of all offerings at once
  const [isVisibleTestimonials, setIsVisibleTestimonials] = useState(false); // State to control the visibility of all testimonials at once

  const offerings = [
    { icon: 'users', content: 'A strong community of learners and doers from all backgrounds and skill levels.' },
    { icon: 'money', content: 'Free resources for everybody.' },
    { icon: 'file code', content: 'Projects to work on to enhance your portfolio' },
    { icon: 'smile', content: 'Resources to get credentialled and learn together' },
  ];

  const testimonials = [
    { author: 'This could be You!', content: 'Coming soon ... :)' },
  ];

  useEffect(() => {
    const missionTimeout = setTimeout(() => setIsVisibleMission(true), 1000); // Delay for the mission segment
    return () => clearTimeout(missionTimeout);
  }, []);

  useEffect(() => {
    if (isVisibleMission) {
      const offeringsTimeout = setTimeout(() => setIsVisibleOfferings(true), 1000); // Delay for all offerings
      return () => clearTimeout(offeringsTimeout);
    }
  }, [isVisibleMission]);

  useEffect(() => {
    if (isVisibleOfferings) {
      const testimonialsTimeout = setTimeout(() => setIsVisibleTestimonials(true), 1000); // Delay for all testimonials
      return () => clearTimeout(testimonialsTimeout);
    }
  }, [isVisibleOfferings]);

  return (
    <Container>
      <Transition visible={isVisibleMission} animation='fade up' duration={500}>
        <Segment id="mission" basic>
          <Header as='h2'>Our Mission</Header>
          <p>Our mission is to empower individuals by providing the best opportunities and tools for career advancement.</p>
        </Segment>
      </Transition>

      <Transition visible={isVisibleOfferings} animation='fade up' duration={500}>
        <Segment>
          <Header as='h2'>What We Offer</Header>
          <List>
            {offerings.map((offering, index) => (
              <ListItem key={index}>
                <ListIcon name={offering.icon} />
                <ListContent>{offering.content}</ListContent>
              </ListItem>
            ))}
          </List>
        </Segment>
      </Transition>

      <Transition visible={isVisibleTestimonials} animation='fade up' duration={500}>
        <Segment>
          <Header as='h2'>Testimonials</Header>
          <Card.Group>
            {testimonials.map((testimonial, index) => (
              <Card key={index}>
                <Card.Content>
                  <Card.Description>{testimonial.content}</Card.Description>
                </Card.Content>
                <Card.Content extra>{testimonial.author}</Card.Content>
              </Card>
            ))}
          </Card.Group>
        </Segment>
      </Transition>
    </Container>
  );
};

export default LandingPage;
