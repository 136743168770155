import React, { useState, useEffect } from 'react';
import { Card, Pagination, Segment, Grid, Message } from 'semantic-ui-react';
import CompactResumeView from './CompactResumeView'; // Assuming the compact resume component

const CompactResumesList = () => {
    const [resumes, setResumes] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [filters, setFilters] = useState({
        page: 1
    });

    useEffect(() => {
        fetchResumes();
    }, [filters]);

    const fetchResumes = async () => {
        const { page } = filters;
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND}/api/resume/compact?page=${page}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    },
                }
            );
            const data = await response.json();
            setResumes(data.resumes);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Error fetching resumes:', error);
        }
    };

    const handlePageChange = (e, { activePage }) => {
        setFilters({ ...filters, page: activePage });
    };

    return (
        <Segment>
            <Grid padded>
                {/* Compact Resumes List */}
                <Grid.Row>
                    {resumes.length > 0 ? (
                        <Card.Group>
                            {resumes.map((resume) => (
                                <Card key={resume._id}>
                                    <Card.Content>
                                        {/* Replace with CompactResumeView */}
                                        <CompactResumeView resume={resume} />
                                    </Card.Content>

                                </Card>
                            ))}
                        </Card.Group>
                    ) : (
                        <Message info>
                            <Message.Header>No Resumes Found</Message.Header>
                            <p>No resumes match the selected criteria. Please try again later.</p>
                        </Message>
                    )}
                </Grid.Row>

                {/* Pagination */}
                <Grid.Row>
                    <Pagination
                        defaultActivePage={1}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        fluid
                        style={{justifyContent: 'center'}}
                        secondary
                    />
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default CompactResumesList;