import React, {useCallback, useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Register from './components/Register';
import Login from './components/Login';
import Profile from './components/Profile'
import Projects from './components/Projects'
import Account from './components/Account'
import 'semantic-ui-css/semantic.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JobsViewAdmin from './components/JobsViewAdmin';
import JobsView from './components/JobsView';
import MenuBar from './MenuBar';
import { allowFeature } from './feature_flags_service';
import ResetPasswordForm from './components/PasswordResetForm';
import LandingPage from './LandingPage';
import Footer from './components/Footer';
import EventsFeed from './components/EventsFeed';
import AdminConsole from './components/AdminConsole';
import ProjectPage from './components/ProjectPage';
import UserPage from './components/UserPage';
import { checkTokenValidity } from './common_frontend_requests';
import UsersList from './components/UsersList';
import NotificationsPage from './components/NotificationsPage';
import Pricing from './components/Pricing';
import RequireAuth from './RequireAuth';
import UsersAndResumes from './components/UsersAndResumes';
import ResumeForReviewPage from './components/ResumeForReviewPage';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [roles, setRoles] = useState(false);
  const [membershipData, setMembershipData] = useState({
    membership_type: 'free',
    ai_credits: 0,
  });
  const [currentRole, setCurrentRole] = useState('');
  const [flags, setFlags] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const handleRoleUpdate = (role) => {
    setCurrentRole(role)
  }

  const isAdmin = useCallback(() => {
    return currentRole === 'admin'
  }, [currentRole])

  const fetchUnreadNotificationsCount = async() => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/notifications/count`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      })
      if (!response.ok) {
          console.error(`Error fetching notifications`)
          return
      };
      const result = await response.json()
      setUnreadCount(result.count);
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchUnreadNotificationsCount();
    }
  }, [isLoggedIn])

  useEffect(() => {
    const validateToken = async () => {
      const data = await checkTokenValidity();
      setIsLoggedIn(data.isValid);
      setRoles(data.user && data.user.roles);
      setMembershipData({
        membership_type: data.user && data.user.membership_type,
        ai_credits: data.user && data.user.ai_credits
      });
    };

  validateToken();

  const fetchFlags = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/admin/feature-flags`,
    {
      headers: {
          'Content-Type': 'application/json',
        },
    }
    );
    const data = await response.json();
    setFlags(data);
  };

  fetchFlags();
  }, []);

  const ProjectPageLayout = ({ isLoggedIn, flags, currentRole, currentTab, isAdmin, unreadCount, project_id }) => {
    return (
      <>
        {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={isAdmin} unreadCount={unreadCount} />}
        <ProjectPage isLoggedIn={isLoggedIn} currentRole={currentRole} currentTab={currentTab} projectId={project_id} />
      </>
    );
  };

  const ProtectedProjectPageLayout = RequireAuth(ProjectPageLayout);

  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', overflow: 'auto' }}>
        <ToastContainer position="top-right" autoClose={3000} />
        {isLoggedIn ? 
        <Navbar isLoggedIn={isLoggedIn} roles={roles} handleRoleUpdate={handleRoleUpdate} membershipData={membershipData}/>
        : <Navbar isLoggedIn={isLoggedIn}/>}
        <div style={{ flex: 1 }}>
        <Routes>
          <Route path="/register" element={<Register isLoggedIn={isLoggedIn} />} />
          <Route path="/account" element={<Account isAdmin={isAdmin()} isLoggedIn={isLoggedIn}/>} />
          <Route path="/login" element={<Login isLoggedIn={isLoggedIn} />} />
          <Route path="/password_reset" element={<ResetPasswordForm />} />
          <Route path="/profile" element={<>
            {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={isAdmin()} unreadCount={unreadCount}/>}
            <Profile flags={flags}/>
          </>} />
          <Route path="/notifications" element={<>
            {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={isAdmin()} fetchUnreadNotificationsCount={fetchUnreadNotificationsCount} unreadCount={unreadCount}/> }
            <NotificationsPage fetchUnreadNotificationsCount={fetchUnreadNotificationsCount}/>
          </>} />
          <Route path="/users" element={<>
            {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={isAdmin()} unreadCount={unreadCount}/>}
            <UsersAndResumes currentTab={'users'} isLoggedIn={isLoggedIn}/>
          </>} />
          <Route path="/resumes" element={<>
            {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={isAdmin()} unreadCount={unreadCount}/>}
            <UsersAndResumes currentTab={'resumes'} isLoggedIn={isLoggedIn}/>
          </>} />
          <Route path="/jobs" element={
          isAdmin() ?
            <JobsViewAdmin isLoggedIn={isLoggedIn} currentRole={currentRole} flags={flags} /> :
            <JobsView className="content" flags={flags} isLoggedIn={isLoggedIn} currentRole={currentRole} />
          } 
          />
          {
            isAdmin() ?
            <Route path="/admin_console" element={
              <AdminConsole isLoggedIn={isLoggedIn} currentRole={currentRole} flags={flags}/>
            } /> : null
          }
          {
          allowFeature(flags, 'projects') &&
          <Route path="/projects" element={
            <>
              {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={isAdmin()} unreadCount={unreadCount}/>}
              <Projects isLoggedIn={isLoggedIn} currentRole={currentRole}/>
            </>
          } 
          />
          }
          {
          allowFeature(flags, 'projects') &&
          <Route path="/allProjects" element={
            <>
              {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={isAdmin()} unreadCount={unreadCount}/>}
              <Projects isLoggedIn={isLoggedIn} currentRole={currentRole} currentTab={'allProjects'}/>
            </>
          } 
          />
          }
          {
          allowFeature(flags, 'projects') &&
          <Route path="/allCourses" element={
            <>
              {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={isAdmin()} unreadCount={unreadCount}/>}
              <Projects isLoggedIn={isLoggedIn} currentRole={currentRole} currentTab={'allCourses'}/>
            </>
          } 
          />
          }
          {
          allowFeature(flags, 'projects') &&
          <Route path="/myGarage" element={
            <>
              {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={isAdmin()} unreadCount={unreadCount}/>}
              <Projects isLoggedIn={isLoggedIn} currentRole={currentRole} currentTab={'myGarage'}/>
            </>
          } 
          />
          }
          {
          allowFeature(flags, 'projects') &&
          <Route path="/projects/:project_id" element={
            <ProtectedProjectPageLayout isLoggedIn={isLoggedIn} flags={flags} currentRole={currentRole} isAdmin={isAdmin()} unreadCount={unreadCount} />
          } 
          />
          }
          <Route path="/users/:user_id" element={
            <>
              {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={isAdmin()} unreadCount={unreadCount} />}
              <UserPage />
            </>
          }
          />
          <Route path="/resumes/:resume_id" element={
            <>
              {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={isAdmin()} unreadCount={unreadCount} />}
              <ResumeForReviewPage />
            </>
          }
          />
          <Route path="/" element={
            <>
              {isLoggedIn && <MenuBar flags={flags} isLoggedIn={isLoggedIn} isAdmin={isAdmin()} unreadCount={unreadCount}/>}
              {isLoggedIn ? 
                <EventsFeed />
                :
                <LandingPage />
              }
            </>
          } />
          <Route path="/about" element={
            <LandingPage />
          } />
          <Route path="/pricing" element={
            <>
              <Pricing />
            </>
          } />
          <Route path="/about" element={
            <LandingPage />
          } />
        </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
